






import { FormContact } from "@/components/ContactData";
import Vue from "vue";
import { mapActions } from "vuex";

export default Vue.extend({
  name: "ContactCreatePage",
  components: {
    FormContact,
  },
  mounted() {
    this.resetContactFormState();
    this.setDefaultAccount();
  },
  methods: {
    ...mapActions({
      resetContactFormState: "contactStore/resetContactFormState",
      setDefaultAccount: "contactStore/setDefaultAccount",
    }),
  },
});
